import { Injectable, OnInit, OnDestroy } from '@angular/core';
import {GlobalmemService} from '../Services/globalmem.service';

export enum access {
  ADMIN_USERS = 13,
  QUEST_QUESTPOINTS = 14,
  QUEST_QUESTS = 15,
  ADMIN_CONFIG = 16
}

export enum allow {
  SUPER = 'S',
  DELETE = 'H',
  ADD = 'A',
  EDIT = 'E',
  VIEW = 'V',
  OMIT = 'O',
  BAD = 'B'
}

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService implements OnInit, OnDestroy {

  // Position 0 is company name length
  // Position 1 - 12 are company codes

  userRights = '';
  constructor(private global: GlobalmemService) { }

  ngOnInit() {
    this.global.questGA.subscribe((tr) => {this.userRights = tr; });
  }

  authorized(sec: number, typ: allow, exact = false) {
    return this.authorized_pass(sec, typ, this.userRights, exact);
  }
  // sec = char position 13 and above
  authorized_pass(sec: number, typ: allow, rights: string, exact = false) {
    const ulen = rights.length;
    if (ulen <= sec) {
      return false;
    }
    const userVal = rights.substr(sec, 1);
    if (exact) {
      if (typ === userVal) {
        return true;
      } else {
        return false;
      }
    }

    const uv = this.allowToLevel( this.charToAllow(userVal));
    const tv = this.allowToLevel(typ);
    if (uv >= tv) {
      return true;
    } else {
      return false;
    }
  }


  // higher the level the more rights
  allowToLevel(theVal: allow): number {
    switch (theVal) {
      case allow.SUPER:
        return 6;
      case allow.DELETE:
        return 5;
      case allow.ADD:
        return 4;
      case allow.EDIT:
        return 3;
      case allow.VIEW:
        return 2;
      case allow.OMIT:
        return 1;
      default:
        return 0;
    }
  }

  charToAllow(theChar: string): allow {
    switch (theChar.toUpperCase()) {
      case 'S':
        return allow.SUPER;
      case 'A':
        return allow.ADD;
      case 'H':
        return allow.DELETE;
      case 'E':
        return allow.EDIT;
      case 'V':
        return allow.VIEW;
      case 'O':
        return allow.OMIT;
      default:
        return allow.BAD;
    }
  }


  getCompany(): string {
    const ulen = this.userRights.length;
    let companyLen = 0;
    if (ulen > 0) {
      companyLen = +this.userRights[0];
      if (ulen > companyLen) {
        return this.userRights.substr(1, companyLen);
      }
    }
    return '';
  }

  ngOnDestroy() {
    this.global.questGA.unsubscribe();
  }
}

